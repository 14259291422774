import { Link } from "react-router-dom";
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import H2 from "../components/H2"

import partners from '../data/partners';
import members from '../data/members';
import teamMembers from '../data/team-members';

export default function AboutUs() {
  const [selectedTeam, setSelectedTeam] = useState("boardMembers");

  return (
    <div>
      <H2 title="Our mission" />
      <div>
        <p>
          Founded in 2017, the Brussels-Africa Hub is an international non-profit organization, led by Banking and Finance professionals from the African diaspora, with the mission to position Brussels as the European hub for impactful, sustainable and responsible  investments towards Africa and unlock the continent's true potential.
        </p>
        <p className='mt-2'>
          We are an ecosystem of business leaders mainly active in the Banking & Finance industry who contribute to the sustainable economic growth of African economies by facilitating investments, and fostering collaboration and knowledge-sharing among stakeholders in Europe and Africa.
        </p>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 mt-4">
          <img className="max-w-full" src={process.env.PUBLIC_URL + "/img/africa.jpg"} alt="" />
        </div>
        <div className="w-full md:w-1/2 mt-4 md:mt-8">
          <h3 className="font-bold">Our initiatives</h3>
          <p className="mt-0.25">
            We achieve our mission through several initiatives centred around four pillars:
          </p>
          <ul className="list-disc pl-8 pr-12">
            <li className="mt-2">
              <b>Investment & Advisory initiatives</b><br/>
              Through our network of business professionals, corporate advisors and investors, we help governments, public & private organisations, and entrepreneurs structure, fund and implement their Africa-related projects.
            </li>
            <li className="mt-2">
              <b>Research & Policymaking initiatives</b><br/>
              We aim to advance the debate and help establish the right framework for investments that produce a long-lasting impact in Africa.
            </li>
            <li className="mt-2">
              <b>Capacity-building initiatives</b><br/>
              In partnership with the likes of INSEAD, we provide a world-class finance and banking education for Africa-based professionals with relevant work experience – from more specialized advanced topics to the basics of finance, banking and sustainability.
            </li>
            <li className="mt-2">
              <b>Networking & Stakeholder engagement initiatives</b><br/>
              We organise periodic events and field trips, in Europe and Africa, to spur genuine conversations that could lead to long-lasting relationships between business leaders, investors and policymakers on both continents.
            </li>
          </ul>
        </div>
      </div>
      <p className="text-center mt-20 mb-24">
        <i className="text-2xl">Do you want to learn more about the Brussels-Africa Hub and its initiatives?</i><br/>
        <Link to="/contact" className="mt-4 inline-block rounded-full bg-bahyellow px-6 py-3">Contact Us</Link>
      </p>
      <H2 title="Our partners" />
      <div className="flex flex-wrap items-center mt-6 mb-16">
        {partners.map((partner) => (
          <a key={partner.link} href={partner.link} target="_blank" rel="noopener noreferrer" className="mb-3 mr-5"><img className="w-36" src={partner.logo} alt={partner.name} /></a>
        ))}
      </div>
      <H2 title="Our members" />
      <div className="flex flex-wrap items-center mt-6 mb-16">
        {members.map((member) => (
          <a key={member.link} href={member.link} target="_blank" rel="noopener noreferrer" className="mb-3 mr-5"><img className="w-36" src={member.logo} alt={member.name} /></a>
        ))}
      </div>
      <H2 title="Our team" />
      <div class="sm:hidden">
        <label for="teamTabs" className="sr-only">Select team</label>
        <select id="teamTabs" onChange={(event) => setSelectedTeam(event.target.value)} value={selectedTeam} className="bg-gray-50 border border-gray-300 rounded-lg block w-full p-2.5">
          <option value="boardMembers">Board members</option>
          <option value="exco">ExCo</option>
          <option value="advisoryBoardMembers">Advisory board members</option>
          <option value="foundingMembers">Founding members</option>
        </select>
      </div>
      <ul className="hidden sm:flex divide-x text-center">
        <li className={selectedTeam === "boardMembers" ? "font-bold w-full" : "w-full"}>
          <button className="inline-block w-full p-4" onClick={() => setSelectedTeam("boardMembers")}>Board members</button>
        </li>
        <li className={selectedTeam === "exco" ? "font-bold w-full" : "w-full"}>
          <button className="inline-block w-full p-4" onClick={() => setSelectedTeam("exco")}>ExCo</button>
        </li>
        <li className={selectedTeam === "advisoryBoardMembers" ? "font-bold w-full" : "w-full"}>
          <button className="inline-block w-full p-4" onClick={() => setSelectedTeam("advisoryBoardMembers")}>Advisory board members</button>
        </li>
        <li className={selectedTeam === "foundingMembers" ? "font-bold w-full" : "w-full"}>
          <button className="inline-block w-full p-4" onClick={() => setSelectedTeam("foundingMembers")}>Founding members</button>
        </li>
      </ul>
      <div className={selectedTeam === "boardMembers" ? "flex flex-wrap items-start" : "hidden"}>
        {teamMembers.filter((teamMember) => teamMember.teams.includes("boardMembers")).map((teamMember) => (
          <div key={teamMember.linkedin} className="flex flex-col w-1/2 md:w-1/3 px-10 mt-8 mb-6">
            <img className="w-2/5 self-center rounded-full border-4 border-stone-300" src={teamMember.image} alt={teamMember.name} />
            <div className="flex flex-row text-lg justify-center items-center font-bold">
              <a href={teamMember.linkedin} target="_blank" rel="noopener noreferrer">{teamMember.name}</a>
              <a href={teamMember.linkedin} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} className="ml-2 text-blue-500"/></a>
            </div>
            <span className="block text-center font-semibold">{teamMember.position}</span>
            <span className="block text-sm mt-2">Also:</span>
            <ul className="mt-2 list-disc pl-8 text-sm">
            {teamMember.bio.map((bioLine) => (
              <li key={bioLine}>{bioLine}</li>
            ))}
            </ul>
          </div>
        ))}
      </div>
      <div className={selectedTeam === "exco" ? "flex flex-wrap items-start" : "hidden"}>
        {teamMembers.filter((teamMember) => teamMember.teams.includes("exco")).map((teamMember) => (
          <div key={teamMember.linkedin} className="flex flex-col w-1/2 md:w-1/3 px-10 mt-8 mb-6">
            <img className="w-2/5 self-center rounded-full border-4 border-stone-300" src={teamMember.image} alt={teamMember.name} />
            <div className="flex flex-row text-lg justify-center items-center font-bold">
              <a href={teamMember.linkedin} target="_blank" rel="noopener noreferrer">{teamMember.name}</a>
              <a href={teamMember.linkedin} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} className="ml-2 text-blue-500"/></a>
            </div>
            <span className="block text-center font-semibold">{teamMember.position}</span>
            <span className="block text-sm mt-2">Also:</span>
            <ul className="mt-2 list-disc pl-8 text-sm">
            {teamMember.bio.map((bioLine) => (
              <li key={bioLine}>{bioLine}</li>
            ))}
            </ul>
          </div>
        ))}
      </div>
      <div className={selectedTeam === "advisoryBoardMembers" ? "flex flex-wrap items-start" : "hidden"}>
        {teamMembers.filter((teamMember) => teamMember.teams.includes("advisoryBoardMembers")).map((teamMember) => (
          <div key={teamMember.linkedin} className="flex flex-col w-1/2 md:w-1/3 px-10 mt-8 mb-6">
            <img className="w-2/5 self-center rounded-full border-4 border-stone-300" src={teamMember.image} alt={teamMember.name} />
            <div className="flex flex-row text-lg justify-center items-center font-bold">
              <a href={teamMember.linkedin} target="_blank" rel="noopener noreferrer">{teamMember.name}</a>
              <a href={teamMember.linkedin} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} className="ml-2 text-blue-500"/></a>
            </div>
            <span className="block text-center font-semibold">{teamMember.position}</span>
            <span className="block text-sm mt-2">Also:</span>
            <ul className="mt-2 list-disc pl-8 text-sm">
            {teamMember.bio.map((bioLine) => (
              <li key={bioLine}>{bioLine}</li>
            ))}
            </ul>
          </div>
        ))}
      </div>
      <div className={selectedTeam === "foundingMembers" ? "flex flex-wrap items-start" : "hidden"}>
        {teamMembers.filter((teamMember) => teamMember.teams.includes("foundingMembers")).map((teamMember) => (
          <div key={teamMember.linkedin} className="flex flex-col w-1/2 md:w-1/3 px-10 mt-8 mb-6">
            <img className="w-2/5 self-center rounded-full border-4 border-stone-300" src={teamMember.image} alt={teamMember.name} />
            <div className="flex flex-row text-lg justify-center items-center font-bold">
              <a href={teamMember.linkedin} target="_blank" rel="noopener noreferrer">{teamMember.name}</a>
              <a href={teamMember.linkedin} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} className="ml-2 text-blue-500"/></a>
            </div>
            <span className="block text-center font-semibold">{teamMember.position}</span>
            <span className="block text-sm mt-2">Also:</span>
            <ul className="mt-2 list-disc pl-8 text-sm">
            {teamMember.bio.map((bioLine) => (
              <li key={bioLine}>{bioLine}</li>
            ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}