import H2 from "../components/H2"
import Event from "../components/Event"

import events from '../data/events';

export default function Events() {

  const now = Date.now();
  const upcomingEvents = events.filter((event) => event.dateTimeTo ? (Date.parse(event.dateTimeTo) >= now) : (Date.parse(event.dateTime) >= now));
  const pastEvents = events.filter((event) => event.dateTimeTo ? (Date.parse(event.dateTimeTo) < now) : (Date.parse(event.dateTime) < now));
  const upcomingEventsSorted = [...upcomingEvents].sort((eventA, eventB) => (eventB.dateTime || eventB.dateTimeFrom) - (eventA.dateTime || eventA.dateTimeFrom));
  const pastEventsSorted = [...pastEvents].sort((eventA, eventB) => (eventB.dateTime || eventB.dateTimeFrom) - (eventA.dateTime || eventA.dateTimeFrom));

  return (
    <div>
      <p className="mt-8">
        The Brussels-Africa Hub regularly organizes events around a certain topic. With these events we aim to:
      </p>
      <ol className="mt-4 list-decimal pl-8">
        <li>Showcase carefully selected, investible projects in Africa</li>
        <li>Offer a stage to African leaders to promote their policies towards improving development in the private sector</li>
        <li>Give the opportunity to African and Africa focused European entrepreneurs to defend their business in front of potential future investors</li>
        <li>Invite EU public figures to exchange on the topic of green finance</li>
        <li>Invite Academics and Researchers to share their view on the latest research on <i>"Sustainable &amp; Responsible Investment"</i> in Africa as well as the impact of <i>Brussels-Africa Hub</i>’s initiatives</li>
      </ol>
      <H2 title="Upcoming events" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
        {upcomingEventsSorted.map((event) => <Event key={event.id} event={event} />)}
      </div>
      <H2 title="Past events" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
        {pastEventsSorted.map((event) => <Event key={event.id} event={event} />)}
      </div>
    </div>
  );
}