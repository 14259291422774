import React from 'react';
import ReactDOM from 'react-dom/client';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import ErrorPage from './error-page';

import Root from "./routes/root";
import Home from "./routes/home";
import Post from "./routes/post";
import AboutUs from "./routes/about-us";
import Events from "./routes/events";
import Event from "./routes/event";
import Contact from "./routes/contact";
import JoinUs from "./routes/join-us";
import RequestFunding from "./routes/request-funding";
import RequestFundingStartupScaleup from "./routes/request-funding-startup-scaleup";
import RequestFundingLargeProject from "./routes/request-funding-large-project";

import './index.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "posts/:id",
        element: <Post />,
      },
      {
        path: "posts/:id/:slug",
        element: <Post />,
      },
      {
        path: "about-us",
        element: <AboutUs />,
      },
      {
        path: "events",
        element: <Events />,
      },
      {
        path: "events/:id",
        element: <Event />,
      },
      {
        path: "events/:id/:slug",
        element: <Event />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "join-us",
        element: <JoinUs />,
      },
      {
        path: "request-funding",
        element: <RequestFunding />,
      },
      {
        path: "request-funding-startup-scaleup",
        element: <RequestFundingStartupScaleup />,
      },
      {
        path: "request-funding-large-project",
        element: <RequestFundingLargeProject />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);