import { Link, useParams } from "react-router-dom";
import { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Download from "yet-another-react-lightbox/plugins/download";

import H2 from "../components/H2"
import events from '../data/events';

import { getTime } from '../utils/format-dates';

export default function Event(props) {

  let { id } = useParams();
  let event = events.find((event) => event.id === id);

  const thumbnails = event.photos && event.photos.length > 0 ? event.photos.map((photo) => ({src: process.env.PUBLIC_URL + "/img/events/" + event.id + "/thumbnails/" + photo})) : [];
  const photos = event.photos && event.photos.length > 0 ? event.photos.map((photo) => ({src: process.env.PUBLIC_URL + "/img/events/" + event.id + "/photos/" + photo})) : [];

  const [index, setIndex] = useState(-1);
  const handleClick = (index) => setIndex(index);

  return (
    <div>
      <nav className="flex mt-4" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li>
            <div className="flex items-center">
              <Link to="/events" className="text-sm font-medium text-gray-700">Events</Link>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg aria-hidden="true" className="w-5 h-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span className="ml-1 md:ml-2 text-sm font-medium text-bahyellow">{event.title}</span>
            </div>
          </li>
        </ol>
      </nav>
      <H2 title={event.title} />
      {event.description &&
        <div dangerouslySetInnerHTML={{__html:event.description}}></div>
      }
      {(event.agendaIntro || (event.agenda && event.agenda.length > 0)) &&
        <>
          <H2 title="Agenda" />
          {event.agendaIntro &&
            (<div dangerouslySetInnerHTML={{__html:event.agendaIntro}} />)
          }
          {(event.agenda && event.agenda.length > 0) &&
            <ol className={`${event.agendaIntro ? 'mt-8' : 'mt-6'} relative border-l border-gray-200 ml-3 w-full pr-2 md:w-3/4 md:pr-0 lg:w-3/5 xl:w-1/2`}>  
            {event.agenda.map((agendaItem) => (
              <li key={agendaItem.title} className="mb-10 ml-6">            
                <span className="mt-1 absolute flex items-center justify-center w-6 h-6 bg-bahyellowlight rounded-full -left-3 ring-8 ring-white">
                  <svg aria-hidden="true" className="w-3 h-3 text-bahyellow" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path>
                  </svg>
                </span>
                <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                  {
                    agendaItem.link ?
                      <h3 className="flex items-center text-lg font-semibold text-blue-500"><a href={agendaItem.link} target="_blank" rel="noopener noreferrer">{agendaItem.title}</a></h3>
                      : <h3 className="flex items-center text-lg font-semibold text-gray-900">{agendaItem.title}</h3>
                  }
                  
                  {agendaItem.dateTime &&
                    <time className="block mt-1 text-sm font-normal leading-none text-gray-400">{getTime(new Date(Date.parse(agendaItem.dateTime)))}</time>
                  }
                  {agendaItem.description &&
                    <div className="mt-2 mb-4 text-base font-normal text-gray-600" dangerouslySetInnerHTML={{__html:agendaItem.description}} />
                  }
                </div>
              </li>
            ))}
            </ol>
          }
        </>
      }
      {event.speakers && Array.isArray(event.speakers) && event.speakers.length > 0 &&
        <>
          <H2 title="Speakers" />
          <div className="flex flex-wrap items-start">
            {event.speakers.map((speaker) => (
              <div key={speaker.name} className="flex flex-col w-1/2 md:w-1/3 px-10 mt-8 mb-6">
                <img className="w-2/5 self-center rounded-full border-4 border-stone-300" src={process.env.PUBLIC_URL + "/img/events/" + event.id + "/speakers/" + speaker.photo} alt={speaker.name} />
                <div className="flex flex-row text-lg justify-center items-center font-bold my-2">
                  <a href={speaker.profileURL} target="_blank" rel="noopener noreferrer">{speaker.name}</a>
                  <a href={speaker.profileURL} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} className="ml-2 text-blue-500"/></a>
                </div>
                <span className="block text-center font-semibold mb-1">{speaker.position}</span>
                <div className="text-sm mt-2 event-bio" dangerouslySetInnerHTML={{__html:speaker.bio}} />
              </div>
            ))}
          </div>
        </>
      }
      {event.speakers && !Array.isArray(event.speakers) &&
        <>
          <H2 title="Speakers" />
          <div dangerouslySetInnerHTML={{__html:event.speakers}}></div>
        </>
      }
      {event.targetAudience &&
        <>
          <H2 title="Target audience" />
          <div dangerouslySetInnerHTML={{__html:event.targetAudience}}></div>
        </>
      }
      {event.programDirectors && event.programDirectors.length > 0 &&
        <>
          <H2 title="Program directors" />
          <div className="flex flex-wrap items-start">
            {event.programDirectors.map((programDirector) => (
              <div key={programDirector.name} className="flex flex-col w-1/2 md:w-1/3 px-10 mt-8 mb-6">
                <img className="w-2/5 self-center rounded-full border-4 border-stone-300" src={programDirector.image} alt={programDirector.name} />
                <div className="flex flex-row text-lg justify-center items-center font-bold my-2">
                  <a href={programDirector.profileURL} target="_blank" rel="noopener noreferrer">{programDirector.name}</a>
                  <a href={programDirector.profileURL} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} className="ml-2 text-blue-500"/></a>
                </div>
                <span className="block text-center font-semibold mb-1">{programDirector.position}</span>
                <p className="text-sm mt-2">{programDirector.bio}</p>
              </div>
            ))}
          </div>
        </>
      }
      {event.videos && event.videos.length > 0 &&
        <>
          <H2 title="Videos" />
          <div className="flex flex-wrap items-center mt-6 mb-16">
            {event.videos.map((video) => (
              <iframe key={video} width="560" height="315" src={video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            ))}
          </div>
        </>
      }
      {event.photos && event.photos.length > 0 &&
        <>
          <H2 title="Photos" />
          <Gallery
            images={thumbnails}
            onClick={handleClick}
            enableImageSelection={false}
          />
          <Lightbox
            slides={photos}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            plugins={[Download]}
          />
        </>
      }
      {event.organizers && event.organizers.length > 0 &&
        <>
          <H2 title="Organizers" />
          <div className="flex flex-wrap items-center -m-4 mt-2 mb-12">
            {event.organizers.map((organizer) => (
              <a key={organizer.name} href={organizer.link} target="_blank" rel="noopener noreferrer" className="m-4"><img className="w-72" src={organizer.logo} alt={organizer.name} /></a>
            ))}
          </div>
        </>
      }
      {event.partners && event.partners.length > 0 &&
        <>
          <H2 title="Partners" />
          <div className="flex flex-wrap items-center -m-4 mt-2 mb-12">
            {event.partners.map((partner) => (
              <a key={partner.name} href={partner.link} target="_blank" rel="noopener noreferrer" className="m-4"><img className="w-72" src={partner.logo} alt={partner.name} /></a>
            ))}
          </div>
        </>
      }
      {event.sponsors && event.sponsors.length > 0 &&
        <>
          <H2 title="Sponsors" />
          <div className="flex flex-wrap items-center -m-4 mt-2 mb-12">
            {event.sponsors.map((sponsor) => (
              <a key={sponsor.name} href={sponsor.link} target="_blank" rel="noopener noreferrer" className="m-4"><img className="w-72" src={sponsor.logo} alt={sponsor.name} /></a>
            ))}
          </div>
        </>
      }
    </div>
  );
}