import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import { formatDate, formatDateRange } from '../utils/format-dates';
import { slugify } from '../utils/slugify';

const EventLink = ({event, children}) => (
  event.externalUrl ?
    <a href={event.externalUrl} target="_blank" rel="noopener noreferrer">{children}</a>
    : <Link
        to={`/events/${event.id}/${slugify(event.title)}`}
        onClick={() => { window.scroll({ top: 0, left: 0, behavior: "instant" }); }}
      >
        {children}
      </Link>
)

export default function Event({ event }) {
  return (
    <div className="border">
      <EventLink event={event}><h3 className="border-l-2 p-4 border-bahyellow font-semibold text-lg">{event.title}</h3></EventLink>
      {((event.dateTimeFrom && event.dateTimeTo) || event.dateTime) &&
        <span className="block mt-2 px-4 text-sm">
          <FontAwesomeIcon icon={faCalendarAlt} className="mr-2"/>
          {event.dateTimeFrom && event.dateTimeTo ?
            formatDateRange(new Date(Date.parse(event.dateTimeFrom)), new Date(Date.parse(event.dateTimeTo)))
            : formatDate(new Date(Date.parse(event.dateTime)))
          }
        </span>
      }
      {event.location &&
        <span className="block px-4 text-sm">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2"/>
          {event.location}
        </span>
      }
      <p className="mt-2 px-4 text-sm">{event.summary}</p>
      <span className="block mt-2 mb-4 px-4 text-sm text-blue-500"><EventLink event={event}>+ Read more</EventLink></span>
    </div>
  )
}