import { Link, useParams } from "react-router-dom";

import H2 from "../components/H2"

import posts from '../data/posts';

import { formatDate } from '../utils/format-dates';

export default function Post() {

  let { id } = useParams();
  let post = posts.find((post) => post.id === id);

  return (
    <div className="flex flex-col mb-24">
      <nav className="flex mt-4" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li>
            <div className="flex items-center">
              <Link to="/" className="text-sm font-medium text-gray-700">Home</Link>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg aria-hidden="true" className="w-5 h-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span className="ml-1 md:ml-2 text-sm font-medium text-bahyellow">{post.title}</span>
            </div>
          </li>
        </ol>
      </nav>
      <div className="w-full lg:w-2/3 self-center">
        <H2 title={post.title} subtitle={formatDate(post.date)} />
        {post.content &&
          <div className="post-content" dangerouslySetInnerHTML={{__html:post.content}}></div>
        }
      </div>
    </div>
  );
}