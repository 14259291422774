const members = [
  {
    name: 'WTS Global',
    link: 'https://wts.com/global',
    logo: process.env.PUBLIC_URL + '/img/partners/wts-global.jpeg',
  },
  {
    name: 'KMK Africa',
    link: 'https://www.lawpkmafrica.com',
    logo: process.env.PUBLIC_URL + '/img/partners/kmk-africa.jpg',
  },
  {
    name: 'BrightWolves',
    link: 'https://www.brightwolves.com',
    logo: process.env.PUBLIC_URL + '/img/partners/brightwolves.png',
  },
  {
    name: 'Sarens',
    link: 'https://www.sarens.com',
    logo: process.env.PUBLIC_URL + '/img/partners/sarens.png',
  },
  {
    name: 'KOP Farms',
    link: 'http://kopfarms.com',
    logo: process.env.PUBLIC_URL + '/img/partners/kopfarms.png',
  },
  {
    name: 'InvestSuite',
    link: 'https://www.investsuite.com',
    logo: process.env.PUBLIC_URL + '/img/partners/investsuite.jpg',
  },
  {
    name: 'Stropcim',
    link: 'http://stropcim.com',
    logo: process.env.PUBLIC_URL + '/img/partners/stropcim.png',
  },
  {
    name: 'Janson',
    link: 'https://www.janson.be',
    logo: process.env.PUBLIC_URL + '/img/partners/janson.png',
  },
  {
    name: 'Arendt',
    link: 'https://www.arendt.com',
    logo: process.env.PUBLIC_URL + '/img/partners/arendt-blue.png',
  }
];

export default members;