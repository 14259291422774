import { Link } from "react-router-dom";
import React from 'react';

import H2 from "../components/H2"

export default function AboutUs() {
  return (
    <div className="flex flex-col mb-20 lg:mb-32">
      <H2 title="Request funding" />
      <p>
        At the Brussels-Africa Hub, we facilitate capital flows between Europe and Africa and promote impactful investments in support of the <a href="https://au.int/en/agenda2063/overview" target="_blank" rel="noopener noreferrer" className="text-blue-500">African Union Agenda 2063</a> - The Africa We Want and its African Development Goals.
      </p>
      <p className="mt-2">
        Therefore, we invite change-makers who share our vision to contact us and submit their funding request below. We will connect them to the relevant partners in our network ranging from Europe-based Angel networks and crowdfunding platform operators to family offices, funds (PE/VC) and Development Finance Institutions.
      </p>
      <img className="my-8 w-64 self-center" src={process.env.PUBLIC_URL + "/img/agenda-2063-logo.png"} alt="Agenda 2063 - The Africa We Want" />
      <p className="mt-2 font-bold">
        Main criteria are:
      </p>
      <ul className="list-disc pl-8 pr-12 mb-4">
        <li className="mt-2">Priority sectors: Renewable Energy, Health & Pharma, Education, Agribusiness, Financial services (bank, insurance, microfinance), IT & Tech, Manufacturing, Infrastructure, Responsible Mining</li>
        <li className="mt-2">Coverage: Africa</li>
        <li className="mt-2">Managed by local teams & entrepreneurs</li>
        <li className="mt-2">Formally established business</li>
        <li className="mt-2">Clear potential in terms of value creation and local impact in line with one (or more) <b>Agenda 2063 Development Goals</b> (see below)</li>
      </ul>
      <div className="lg:columns-2">
        <div className="flex flex-col lg:pr-6">
          <H2 titleClassName="lg:text-center mt-0" subtitleClassName="lg:text-center" title="Startups / Small & Growing Businesses" subtitle="Funding need: €200.000 to €1 million" />
          <p className="text-justify">We help small and growing businesses with regional or continental ambitions connect with European capital providers and partners investing in Africa.</p>
          <p className="text-center mt-4">
            <Link to="/request-funding-startup-scaleup" className="inline-block rounded-full bg-bahyellow px-6 py-3">Submit your project</Link>
          </p>
        </div>
        <div className="flex flex-col lg:pl-6">
          <H2 titleClassName="lg:text-center" subtitleClassName="lg:text-center" title="Large projects" subtitle="Funding need: €1 million and above" />
          <p className="text-justify">In partnership with <a href="https://app.dealfox.pro/en/about-us" target="_blank" rel="noopener noreferrer" className="text-blue-500">DealFox</a>, we connect entrepreneurs and their trusted advisors (corporate finance advisors, lawyers,...) to a network of 45k+ investors from Europe and beyond.</p>
          <p className="text-center mt-4">
            <Link to="/request-funding-large-project" className="inline-block rounded-full bg-bahyellow px-6 py-3">Contact us</Link><br/>
          </p>
        </div>
      </div>
      <img className="border lg:max-w-3/4 self-center mt-12" src={process.env.PUBLIC_URL + "/img/agenda-2063-goals-infographic.png"} alt="Agenda 2063 Development Goals" />
    </div>
  );
}