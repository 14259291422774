import React from 'react';

import H3 from "../H3"

import { titles, countries, industries } from '../../utils/form-dropdowns';

export default class JoinUsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        position: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        stateProvince: "",
        zipPostalCode: "",
        country: "",
        website: "",
        vatNumber: "",
        industry: "",
        acceptsRoARoIO: false,
        acceptsPrivacyPolicy: false,
      },
      validationErrors: {
        title: null,
        firstName: null,
        lastName: null,
        email: null,
        company: null,
        position: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        stateProvince: null,
        zipPostalCode: null,
        country: null,
        website: null,
        vatNumber: null,
        industry: null,
        acceptsRoARoIO: null,
        acceptsPrivacyPolicy: null,
      },
      isLoading: false,
      responseStatus: null,
    };
  }

  handleInputChange = (field, value) => {
    const validationErrorMessage = this.getValidationErrorMessage(field, value);
    const updatedState = {
      formFields: {
        ...this.state.formFields,
        [field]: value,
      },
      validationErrors: {
        ...this.state.validationErrors,
        [field]: validationErrorMessage,
      }
    };
    this.setState(updatedState);
  }

  getValidationErrorMessage = (field, value) => {
    switch (field) {
      case "title":
        return !value ? "Title is required" : (titles.indexOf(value) < 0 ? `Invalid title. Valid options are: ${titles.join(', ')}` : null);
      case "firstName":
        return !value ? "First name is required" : null;
      case "lastName":
        return !value ? "Last name is required" : null;
      case "email":
        return !value ? "Email is required" : (!/^\S+@\S+$/.test(value) ? "Invalid email" : null);
      case "company":
        return !value ? "Company is required" : null;
      case "position":
        return !value ? "Position is required" : null;
      case "addressLine1":
        return !value ? "Address is required" : null;
      case "city":
        return !value ? "City is required" : null;
      case "zipPostalCode":
        return !value ? "Zip / postal code is required" : null;
      case "country":
        return !value ? "Country is required" : (countries.indexOf(value) < 0 ? `Invalid country. Valid options are: ${countries.join(', ')}` : null);
      case "website":
        return !value ? "Website is required" : null;
      case "vatNumber":
        return !value ? "VAT number is required" : null;
      case "industry":
        return !value ? "Industry is required" : (industries.indexOf(value) < 0 ? `Invalid industry. Valid options are: ${industries.join(', ')}` : null);
      case "acceptsRoARoIO":
        return !value ? "Accepting the Rules of Association and the Rules of Internal Order is required" : null;
      case "acceptsPrivacyPolicy":
        return !value ? "Accepting the privacy policy is required" : null;
      default:
        return null;
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState((state) => {
      let formHasValidationErrors = false;
      const validationErrors = Object.keys(state.formFields).reduce((previousValue, field) => {
        const validationErrorMessage = this.getValidationErrorMessage(field, state.formFields[field]);
        if (validationErrorMessage) {
          formHasValidationErrors = true;
        }
        return {
          ...previousValue,
          [field]: validationErrorMessage,
        };
      }, {});
      return {
        validationErrors,
        isLoading: !formHasValidationErrors,
      };
    }, () => {
      if (this.state.isLoading) {
        fetch("https://api.brusselsafricahub.eu/join", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            title: this.state.formFields.title,
            firstName: this.state.formFields.firstName,
            lastName: this.state.formFields.lastName,
            email: this.state.formFields.email,
            company: this.state.formFields.company,
            position: this.state.formFields.position,
            addressLine1: this.state.formFields.addressLine1,
            addressLine2: this.state.formFields.addressLine2,
            city: this.state.formFields.city,
            stateProvince: this.state.formFields.stateProvince,
            zipPostalCode: this.state.formFields.zipPostalCode,
            country: this.state.formFields.country,
            website: this.state.formFields.website,
            vatNumber: this.state.formFields.vatNumber,
            industry: this.state.formFields.industry,
            acceptsRoARoIO: this.state.formFields.acceptsRoARoIO,
            acceptsPrivacyPolicy: this.state.formFields.acceptsPrivacyPolicy,
          }),
        }).then((response) => response.text().then((data) => {
            const validationErrors = data ? JSON.parse(data).validationErrors : {};
            this.setState((state) => ({
              validationErrors: {
                ...state.validationErrors,
                ...validationErrors,
              },
              isLoading: false,
              responseStatus: response.status,
            }));
          })
        )
        .catch((error) => {
          this.setState({
            isLoading: false,
            responseStatus: 500
          });
        });
      }
    });
  }

  render() {
    return (
      <>
        {(!this.state.responseStatus || this.state.responseStatus === 400) &&
          <form onSubmit={this.handleSubmit} className="flex flex-col items-start">
            <H3 className="mt-5">Personal info</H3>
            <div className="w-full flex flex-wrap mt-4">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="title">Title <span className="text-red-500">*</span></label>
              </div>
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <select
                  id="title"
                  value={this.state.formFields.title}
                  onChange={(event) => this.handleInputChange('title', event.target.value)}
                  className={`${this.state.validationErrors.title ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                >
                  {!this.state.formFields.title && <option></option>}
                  {titles.map((title) =>
                    <option key={title}>{title}</option>
                  )}
                </select>
                {this.state.validationErrors.title && <span className="text-red-500 text-sm">{this.state.validationErrors.title}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="firstName">First name <span className="text-red-500">*</span></label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <input
                  id="firstName"
                  type="text"
                  value={this.state.formFields.firstName}
                  onChange={(event) => this.handleInputChange('firstName', event.target.value)}
                  className={`${this.state.validationErrors.firstName ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                />
                {this.state.validationErrors.firstName && <span className="text-red-500 text-sm">{this.state.validationErrors.firstName}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="lastName">Last name <span className="text-red-500">*</span></label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <input
                  id="lastName"
                  type="text"
                  value={this.state.formFields.lastName}
                  onChange={(event) => this.handleInputChange('lastName', event.target.value)}
                  className={`${this.state.validationErrors.lastName ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                />
                {this.state.validationErrors.lastName && <span className="text-red-500 text-sm">{this.state.validationErrors.lastName}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="email">Email <span className="text-red-500">*</span></label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <input
                  id="email"
                  type="text"
                  value={this.state.formFields.email}
                  onChange={(event) => this.handleInputChange('email', event.target.value)}
                  className={`${this.state.validationErrors.email ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                />
                {this.state.validationErrors.email && <span className="text-red-500 text-sm">{this.state.validationErrors.email}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="company">Company <span className="text-red-500">*</span></label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <input
                  id="company"
                  type="text"
                  value={this.state.formFields.company}
                  onChange={(event) => this.handleInputChange('company', event.target.value)}
                  className={`${this.state.validationErrors.company ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                />
                {this.state.validationErrors.company && <span className="text-red-500 text-sm">{this.state.validationErrors.company}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="position">Position <span className="text-red-500">*</span></label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <input
                  id="position"
                  type="text"
                  value={this.state.formFields.position}
                  onChange={(event) => this.handleInputChange('position', event.target.value)}
                  className={`${this.state.validationErrors.position ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                />
                {this.state.validationErrors.position && <span className="text-red-500 text-sm">{this.state.validationErrors.position}</span>}
              </div>
            </div>
            <H3 className="mt-6">Company info</H3>
            <div className="w-full flex flex-wrap mt-4">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="addressLine1">Address line 1 <span className="text-red-500">*</span></label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <input
                  id="addressLine1"
                  type="text"
                  value={this.state.formFields.addressLine1}
                  onChange={(event) => this.handleInputChange('addressLine1', event.target.value)}
                  className={`${this.state.validationErrors.addressLine1 ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                />
                {this.state.validationErrors.addressLine1 && <span className="text-red-500 text-sm">{this.state.validationErrors.addressLine1}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="addressLine2">Address line 2</label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <input
                  id="addressLine2"
                  type="text"
                  value={this.state.formFields.addressLine2}
                  onChange={(event) => this.handleInputChange('addressLine2', event.target.value)}
                  className={`${this.state.validationErrors.addressLine2 ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                />
                {this.state.validationErrors.addressLine2 && <span className="text-red-500 text-sm">{this.state.validationErrors.addressLine2}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="city">City <span className="text-red-500">*</span></label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <input
                  id="city"
                  type="text"
                  value={this.state.formFields.city}
                  onChange={(event) => this.handleInputChange('city', event.target.value)}
                  className={`${this.state.validationErrors.city ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                />
                {this.state.validationErrors.city && <span className="text-red-500 text-sm">{this.state.validationErrors.city}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="stateProvince">State / Province</label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <input
                  id="stateProvince"
                  type="text"
                  value={this.state.formFields.stateProvince}
                  onChange={(event) => this.handleInputChange('stateProvince', event.target.value)}
                  className={`${this.state.validationErrors.stateProvince ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                />
                {this.state.validationErrors.stateProvince && <span className="text-red-500 text-sm">{this.state.validationErrors.stateProvince}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="zipPostalCode">Zip / Postal code <span className="text-red-500">*</span></label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <input
                  id="zipPostalCode"
                  type="text"
                  value={this.state.formFields.zipPostalCode}
                  onChange={(event) => this.handleInputChange('zipPostalCode', event.target.value)}
                  className={`${this.state.validationErrors.zipPostalCode ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                />
                {this.state.validationErrors.zipPostalCode && <span className="text-red-500 text-sm">{this.state.validationErrors.zipPostalCode}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="country">Country <span className="text-red-500">*</span></label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <select
                  id="country"
                  value={this.state.formFields.country}
                  onChange={(event) => this.handleInputChange('country', event.target.value)}
                  className={`${this.state.validationErrors.country ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                >
                  {!this.state.formFields.country && <option></option>}
                  {countries.map((country) =>
                    <option key={country}>{country}</option>
                  )}
                </select>
                {this.state.validationErrors.country && <span className="text-red-500 text-sm">{this.state.validationErrors.country}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="website">Website <span className="text-red-500">*</span></label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <input
                  id="website"
                  type="text"
                  value={this.state.formFields.website}
                  onChange={(event) => this.handleInputChange('website', event.target.value)}
                  className={`${this.state.validationErrors.website ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                />
                {this.state.validationErrors.website && <span className="text-red-500 text-sm">{this.state.validationErrors.website}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="vatNumber">VAT number <span className="text-red-500">*</span></label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <input
                  id="vatNumber"
                  type="text"
                  value={this.state.formFields.vatNumber}
                  onChange={(event) => this.handleInputChange('vatNumber', event.target.value)}
                  className={`${this.state.validationErrors.vatNumber ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                />
                {this.state.validationErrors.vatNumber && <span className="text-red-500 text-sm">{this.state.validationErrors.vatNumber}</span>}
              </div>
            </div>
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-1/3 md:w-1/4 lg:w-1/6 mt-1.5">
                <label htmlFor="industry">Industry <span className="text-red-500">*</span></label>
              </div>
              <div className="w-2/3 md:w-1/2 xl:w-1/3">
                <select
                  id="industry"
                  value={this.state.formFields.industry}
                  onChange={(event) => this.handleInputChange('industry', event.target.value)}
                  className={`${this.state.validationErrors.industry ? 'border-red-500' : ''} w-full border rounded-md p-1.5`}
                >
                  {!this.state.formFields.industry && <option></option>}
                  {industries.map((industry) =>
                    <option key={industry}>{industry}</option>
                  )}
                </select>
                {this.state.validationErrors.industry && <span className="text-red-500 text-sm">{this.state.validationErrors.industry}</span>}
              </div>
            </div>
            <div className="flex items-start mt-3">
              <input
                id="acceptsRoARoIO"
                type="checkbox"
                checked={this.state.formFields.acceptsRoARoIO}
                onChange={(event) => this.handleInputChange('acceptsRoARoIO', event.target.checked)}
                className={`${this.state.validationErrors.acceptsRoARoIO ? 'border-red-500' : ''} mt-1.5`}
              />
              <div className="flex flex-col pl-4">
                <label htmlFor="acceptsRoARoIO">
                  I confirm that I have read the <a href="http://files.brusselsafricahub.eu/rules-of-association.pdf" className="text-blue-500" target="_blank" rel="noopener noreferrer">Rules of Association</a> and the <a href="http://files.brusselsafricahub.eu/rules-of-internal-order.pdf" className="text-blue-500" target="_blank" rel="noopener noreferrer">Rules of Internal Order</a> and I agree to abide by those rules, in particular to the Statutes. Title II, art. 6 to 10 and the rules of internal order point 1.F + G, and 2.B + E and 4.E <span className="text-red-500">*</span>
                </label>
                {this.state.validationErrors.acceptsRoARoIO && <span className="text-red-500 text-sm">{this.state.validationErrors.acceptsRoARoIO}</span>}
              </div>
            </div>
            <div className="flex items-start mt-3">
              <input
                id="acceptsPrivacyPolicy"
                type="checkbox"
                value={this.state.formFields.acceptsPrivacyPolicy}
                onChange={(event) => this.handleInputChange('acceptsPrivacyPolicy', event.target.checked)}
                className={`${this.state.validationErrors.acceptsPrivacyPolicy ? 'border-red-500' : ''} mt-1.5`}
              />
              <div className="flex flex-col pl-4">
                <label htmlFor="acceptsPrivacyPolicy">
                  I accept that the above information is stored &amp; used by the Brussels-Africa Hub to inform me about news, events, activities and services of the Brussels-Africa Hub, possibly in cooperation with partners <span className="text-red-500">*</span>
                </label>
                {this.state.validationErrors.acceptsPrivacyPolicy && <span className="text-red-500 text-sm">{this.state.validationErrors.acceptsPrivacyPolicy}</span>}
              </div>
            </div>
            {this.state.isLoading ?
              <button
                type="button"
                className="cursor-not-allowed mt-6 rounded-full bg-bahyellow px-6 py-3"
              >
                <svg className="inline-block animate-spin -ml-1 -mt-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="black" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="black" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Sending...
              </button> :
              <button
                type="button"
                className="cursor-pointer mt-6 rounded-full bg-bahyellow px-6 py-3"
                onClick={(event) => this.handleSubmit(event)}
              >
                Submit
              </button>
            }
          </form>
        }
        {this.state.responseStatus === 200 &&
          <span className="block text-green-600">Your message has been received. We will get back to you as soon as possible.</span>
        }
        {this.state.responseStatus && this.state.responseStatus !== 200 && this.state.responseStatus !== 400 &&
          <span className="block text-red-500">Something went wrong. Please email us at <a href="mailto:info@brusselsafricahub.eu">info@brusselsafricahub.eu</a>.</span>
        }
      </>
    );
  }
}