import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
  return (
    <footer className="bg-bahbrown flex flex-col border-bahyellow border-t-2 mt-10 text-white text-sm font-light">
      <div className="flex-1 container flex flex-wrap justify-between mx-auto py-4">
        <div className="w-1/2">
          <ul className="flex flex-col space-y-0.5">
            <li><Link to='/'>Home</Link></li>
            <li><Link to='/about-us'>About us</Link></li>
            <li><Link to='/events'>Events</Link></li>
            <li><Link to='/contact'>Contact</Link></li>
            <li><Link to='/join-us'>Join us</Link></li>
          </ul>
        </div>
        <div className="w-1/2 mb-3">
          <div className="flex items-center">
            <span className="font-semibold">Brussels-Africa Hub AISBL</span>
            <a href="https://www.linkedin.com/company/brussels-africa-hub/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="text-lg ml-4"/>
            </a>
          </div>
          <i>International Non-Profit Organisation</i><br/><br/>
          Place Albert Leemans 14 box 11<br/>
          B 1050 Ixelles, Belgium<br/>
          BE 0682.586.426<br/>
          <a href="mailto:info@brusselsafricahub.eu">info@brusselsafricahub.eu</a><br/>
          <a href="http://eepurl.com/c-4RcT" className="inline-flex items-center mt-5 border border-white rounded-full px-7 py-3" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faEnvelope} className="text-lg mr-2" /> Subscribe to our newsletter
          </a>
        </div>
      </div>
    </footer>
  );
}
