const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export function formatDate(date) {
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return `${monthNames[monthIndex]} ${day}, ${year}`;
}

export function formatDateRange(dateFrom, dateTo) {
  const dayFrom = dateFrom.getDate();
  const monthIndexFrom = dateFrom.getMonth();
  const yearFrom = dateFrom.getFullYear();
  const dayTo = dateTo.getDate();
  const monthIndexTo = dateTo.getMonth();
  const yearTo = dateTo.getFullYear();
  if (monthIndexFrom === monthIndexTo && yearFrom === yearTo) {
    return `${monthNames[monthIndexFrom]} ${dayFrom} - ${dayTo}, ${yearFrom}`;
  } else if (yearFrom === yearTo) {
    return `${monthNames[monthIndexFrom]} ${dayFrom} - ${monthNames[monthIndexTo]} ${dayTo}, ${yearFrom}`;
  } else {
    return `${monthNames[monthIndexFrom]} ${dayFrom}, ${yearFrom} - ${monthNames[monthIndexTo]} ${dayTo}, ${yearTo}`;
  }
}

export function getTime(date) {
  return date.toLocaleTimeString("en-US", { hour12: false, hour: "2-digit", minute:"2-digit", timeZone: "Europe/Brussels", timeZoneName: "short" });
}