import React from 'react';

import { roles, countries, industries } from '../../utils/form-dropdowns';

const companyFoundationOptions = [
  "Less than 1 year",
  "Between 1 and 5 years",
  "More than 5 years",
  "Not yet created"
];

export default class RequestFundingForStartupScaleupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        name: "",
        email: "",
        role: "",
        companyCountry: "",
        companySector: "",
        presentation: "",
        companyFoundation: "",
        companyActivities: "",
        companyTurnoverPastYear: "",
        investmentPurpose: "",
        referral: "",
        files: null,
      },
      validationErrors: {
        name: null,
        email: null,
        role: null,
        companyCountry: null,
        companySector: null,
        presentation: null,
        companyFoundation: null,
        companyActivities: null,
        companyTurnoverPastYear: null,
        investmentPurpose: null,
        referral: null,
        files: null,
      },
      isLoading: false,
      responseStatus: null,
    };
  }

  handleInputChange = (field, value) => {
    const validationErrorMessage = this.getValidationErrorMessage(field, value);
    const updatedState = {
      formFields: {
        ...this.state.formFields,
        [field]: value,
      },
      validationErrors: {
        ...this.state.validationErrors,
        [field]: validationErrorMessage,
      }
    };
    this.setState(updatedState);
  }

  getValidationErrorMessage = (field, value) => {
    switch (field) {
      case "name":
        return !value ? "Name is required" : null;
      case "email":
        return !value ? "Email is required" : (!/^\S+@\S+$/.test(value) ? "Invalid email" : null);
      case "role":
        return value ? (roles.indexOf(value) < 0 ? `Invalid role. Valid options are: ${roles.join(', ')}` : null) : null;
      case "companyCountry":
        return !value ? "Country is required" : (countries.indexOf(value) < 0 ? `Invalid country. Valid options are: ${countries.join(', ')}` : null);
      case "companySector":
        return value ? (industries.indexOf(value) < 0 ? `Invalid sector. Valid options are: ${industries.join(', ')}` : null) : null;
      case "presentation":
        return !value ? "A brief presentation is required" : null;
      case "companyFoundation":
        return value ? (companyFoundationOptions.indexOf(value) < 0 ? `Invalid option. Valid options are: ${companyFoundationOptions.join(', ')}` : null) : null;
      case "companyActivities":
        return !value ? "The company's activities are required" : null;
      case "investmentPurpose":
        return !value ? "The purpose of the investment is required" : null;
      default:
        return null;
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState((state) => {
      let formHasValidationErrors = false;
      const validationErrors = Object.keys(state.formFields).reduce((previousValue, field) => {
        const validationErrorMessage = this.getValidationErrorMessage(field, state.formFields[field]);
        if (validationErrorMessage) {
          formHasValidationErrors = true;
        }
        return {
          ...previousValue,
          [field]: validationErrorMessage,
        };
      }, {});
      return {
        validationErrors,
        isLoading: !formHasValidationErrors,
      };
    }, () => {
      if (this.state.isLoading) {
        fetch("https://api.brusselsafricahub.eu/request-funding-for-startup-scaleup", {
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data"
          },
          body: null, // TO DO
        }).then((response) => response.text().then((data) => {
            const validationErrors = data ? JSON.parse(data).validationErrors : {};
            this.setState((state) => ({
              validationErrors: {
                ...state.validationErrors,
                ...validationErrors,
              },
              isLoading: false,
              responseStatus: response.status,
            }));
          })
        )
        .catch((error) => {
          this.setState({
            isLoading: false,
            responseStatus: 500
          });
        });
      }
    });
  }

  render() {
    return (
      <>
        {(!this.state.responseStatus || this.state.responseStatus === 400) &&
          <form onSubmit={this.handleSubmit} className="flex flex-col items-start">

            <label htmlFor="name" className="mt-1">Name <span className="text-red-500">*</span></label>
            <input
              id="name"
              type="text"
              value={this.state.formFields.name}
              onChange={(event) => this.handleInputChange('name', event.target.value)}
              className={`${this.state.validationErrors.name ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            />
            {this.state.validationErrors.name && <span className="text-red-500 text-sm">{this.state.validationErrors.name}</span>}

            <label htmlFor="email" className="mt-4">Email <span className="text-red-500">*</span></label>
            <input
              id="email"
              type="text"
              value={this.state.formFields.email}
              onChange={(event) => this.handleInputChange('email', event.target.value)}
              className={`${this.state.validationErrors.email ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            />
            {this.state.validationErrors.email && <span className="text-red-500 text-sm">{this.state.validationErrors.email}</span>}

            <label htmlFor="role" className="mt-4">Are you the company's promoter, or an intermediary?</label>
            <select
              id="role"
              value={this.state.formFields.role}
              onChange={(event) => this.handleInputChange('role', event.target.value)}
              className={`${this.state.validationErrors.role ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            >
              <option></option>
              {roles.map((role) =>
                <option key={role}>{role}</option>
              )}
            </select>
            {this.state.validationErrors.role && <span className="text-red-500 text-sm">{this.state.validationErrors.role}</span>}

            <label htmlFor="companyCountry" className="mt-4">Country of registration of the company <span className="text-red-500">*</span></label>
            <select
              id="companyCountry"
              value={this.state.formFields.companyCountry}
              onChange={(event) => this.handleInputChange('companyCountry', event.target.value)}
              className={`${this.state.validationErrors.companyCountry ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            >
              {!this.state.formFields.companyCountry && <option></option>}
              {countries.map((country) =>
                <option key={country}>{country}</option>
              )}
            </select>
            {this.state.validationErrors.companyCountry && <span className="text-red-500 text-sm">{this.state.validationErrors.companyCountry}</span>}

            <label htmlFor="companySector" className="mt-4">Sector of activity</label>
            <select
              id="companySector"
              value={this.state.formFields.companySector}
              onChange={(event) => this.handleInputChange('companySector', event.target.value)}
              className={`${this.state.validationErrors.companySector ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            >
              <option></option>
              {industries.map((industry) =>
                <option key={industry}>{industry}</option>
              )}
            </select>
            {this.state.validationErrors.companySector && <span className="text-red-500 text-sm">{this.state.validationErrors.companySector}</span>}

            <label htmlFor="presentation" className="mt-4">Briefly present yourself (education, professional experience, etc.) <span className="text-red-500">*</span></label>
            <textarea
              id="presentation"
              rows="5"
              value={this.state.formFields.presentation}
              onChange={(event) => this.handleInputChange('presentation', event.target.value)}
              className={`${this.state.validationErrors.presentation ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            />
            {this.state.validationErrors.presentation && <span className="text-red-500 text-sm">{this.state.validationErrors.presentation}</span>}

            <label htmlFor="companyFoundation" className="mt-4">When was the company founded?</label>
            <select
              id="companyFoundation"
              value={this.state.formFields.companyFoundation}
              onChange={(event) => this.handleInputChange('companyFoundation', event.target.value)}
              className={`${this.state.validationErrors.companyFoundation ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            >
              <option></option>
              {companyFoundationOptions.map((companyFoundationOption) =>
                <option key={companyFoundationOption}>{companyFoundationOption}</option>
              )}
            </select>
            {this.state.validationErrors.companyFoundation && <span className="text-red-500 text-sm">{this.state.validationErrors.companyFoundation}</span>}

            <label htmlFor="companyActivities" className="mt-4">Present the company's activities (5 lines) <span className="text-red-500">*</span></label>
            <textarea
              id="companyActivities"
              rows="5"
              value={this.state.formFields.companyActivities}
              onChange={(event) => this.handleInputChange('companyActivities', event.target.value)}
              className={`${this.state.validationErrors.companyActivities ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            />
            {this.state.validationErrors.companyActivities && <span className="text-red-500 text-sm">{this.state.validationErrors.companyActivities}</span>}

            <label htmlFor="companyTurnoverPastYear" className="mt-4">What is the turnover generated by the company over the past year? (in local currency)</label>
            <textarea
              id="companyTurnoverPastYear"
              rows="5"
              value={this.state.formFields.companyTurnoverPastYear}
              onChange={(event) => this.handleInputChange('companyTurnoverPastYear', event.target.value)}
              className={`${this.state.validationErrors.companyTurnoverPastYear ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            />

            <label htmlFor="investmentPurpose" className="mt-4">What is the purpose of the investment need? (5 lines) <span className="text-red-500">*</span></label>
            <textarea
              id="investmentPurpose"
              rows="5"
              value={this.state.formFields.investmentPurpose}
              onChange={(event) => this.handleInputChange('investmentPurpose', event.target.value)}
              className={`${this.state.validationErrors.investmentPurpose ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            />
            {this.state.validationErrors.investmentPurpose && <span className="text-red-500 text-sm">{this.state.validationErrors.investmentPurpose}</span>}

            <label htmlFor="referral" className="mt-1">How did you hear about the Brussels-Africa Hub?</label>
            <input
              id="referral"
              type="text"
              value={this.state.formFields.referral}
              onChange={(event) => this.handleInputChange('referral', event.target.value)}
              className={`${this.state.validationErrors.referral ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            />

            <label htmlFor="files" className="mt-4">Document upload (teaser, NDA,...)</label>
            <input
              id="files"
              type="file"
              name="files"
              multiple
              onChange={(event) => this.handleInputChange('files', event.target.files)}
            />

            {this.state.isLoading ?
              <button
                type="button"
                className="cursor-not-allowed mt-6 rounded-full bg-bahyellow px-6 py-3"
              >
                <svg className="inline-block animate-spin -ml-1 -mt-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="black" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="black" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Sending...
              </button> :
              <button
                type="button"
                className="cursor-pointer mt-6 rounded-full bg-bahyellow px-6 py-3"
                onClick={(event) => this.handleSubmit(event)}
              >
                Submit
              </button>
            }
          </form>
        }
        {this.state.responseStatus === 200 &&
          <span className="block text-green-600">Thank you message: Thank you for submitting your project. We will contact you shortly to discuss your request further and tell you more about the next steps.</span>
        }
        {this.state.responseStatus && this.state.responseStatus !== 200 && this.state.responseStatus !== 400 &&
          <span className="block text-red-500">Something went wrong. Please email us at <a href="mailto:info@brusselsafricahub.eu">info@brusselsafricahub.eu</a>.</span>
        }
      </>
    );
  }
}