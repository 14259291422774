const partners = [
  {
    name: 'african markets',
    link: 'https://www.african-markets.com/en/',
    logo: process.env.PUBLIC_URL + '/img/partners/african-markets.png',
  },
  {
    name: 'LuxAfrica',
    link: 'https://luxafrica.lu/',
    logo: process.env.PUBLIC_URL + '/img/partners/luxafrica.png',
  },
  {
    name: 'OVO',
    link: 'https://ondernemersvoorondernemers.be/',
    logo: process.env.PUBLIC_URL + '/img/partners/ondernemers-voor-ondernemers.jpg',
  },
  {
    name: 'Close The Gap',
    link: 'https://www.close-the-gap.org/',
    logo: process.env.PUBLIC_URL + '/img/partners/close-the-gap.png',
  },
  {
    name: 'The Shift',
    link: 'https://www.theshift.be/',
    logo: process.env.PUBLIC_URL + '/img/partners/the-shift.jpg',
  },
  {
    name: 'ACFA',
    link: 'https://africancrowd.org/',
    logo: process.env.PUBLIC_URL + '/img/partners/acfa.jpg',
  },
  {
    name: 'Canada-Europe Economic Chamber',
    link: 'https://www.canadaeurope.eu/',
    logo: process.env.PUBLIC_URL + '/img/partners/canada-europe.jpg',
  },
  {
    name: 'European Business Summit',
    link: 'https://ebsummit.eu',
    logo: process.env.PUBLIC_URL + '/img/partners/european-business-summit.png',
  },
  {
    name: 'BelgoEthiopian',
    link: 'https://www.belgoethiopian.be/business',
    logo: process.env.PUBLIC_URL + '/img/partners/belgoethiopian.png',
  },
  {
    name: 'VC4A',
    link: 'https://vc4a.com',
    logo: process.env.PUBLIC_URL + '/img/partners/vc4a.png',
  },
  {
    name: 'Makutano',
    link: 'https://makutano.cd/en/',
    logo: process.env.PUBLIC_URL + '/img/partners/makutano.png',
  },
  {
    name: 'Africa-Europe Foundation',
    link: 'https://www.africaeuropefoundation.org/',
    logo: process.env.PUBLIC_URL + '/img/partners/africa-europe-foundation.png',
  },
];

export default partners;