import React, { useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import navItems from '../data/nav-items';

export default function Navigation() {

  const [isNavigationExpanded, setIsNavigationExpanded] = useState(false);

  const toggleNavigation = () => {
    setIsNavigationExpanded(!isNavigationExpanded);
  }

  return (
    <nav className="fixed w-full top-0 bg-white z-10">
      <div className="container flex flex-wrap items-center justify-between mx-auto py-2">
      <Link to="/">
        <img src={process.env.PUBLIC_URL + "/img/logo.jpg"} className="h-16 md:h-20" alt="Brussels-Africa Hub Logo" />
      </Link>
      <button type="button" onClick={() => toggleNavigation()} className="inline-flex items-center p-2 ml-3 rounded-lg md:hidden focus:outline-none">
        <span className="sr-only">Open main menu</span>
        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
        </svg>
      </button>
      <div className={`${isNavigationExpanded ? 'block' : 'hidden'} w-full md:block md:w-auto`}>
        <ul className="flex flex-col p-4 mt-4 md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:mt-0">
          {navItems.map((navItem) => (
            <li key={navItem.link} className="uppercase text-sm">
              {
                navItem.style && navItem.style === "button" ?
                  <NavLink to={navItem.link} className={({ isActive }) => isActive ? 'text-bahbrownyellow md:text-black md:rounded-full md:bg-bahyellow md:px-6 md:py-3' : 'md:text-black md:rounded-full md:bg-bahyellow md:px-6 md:py-3'}>{navItem.name}</NavLink>
                  : <NavLink to={navItem.link} className={({ isActive }) => isActive ? 'text-bahbrownyellow' : undefined}>{navItem.name}</NavLink>
              }
            </li>
          ))}
        </ul>
      </div>
    </div>
  </nav>
  );
}
