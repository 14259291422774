import { Link, useMatch } from "react-router-dom";

export default function Banner() {

 const onHomePage = useMatch("/");

  return (
    <div className="relative mt-20 md:mt-24">
      <div className={`bg-cover blur-[0.5px] h-2 ${onHomePage ? "h-[32rem] bg-bottom" : "bg-top"}`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/img/banner.jpg"})` }} />
      {onHomePage &&
        <div className="absolute top-0 bottom-0 left-0 right-0">
          <div className="container mx-auto">
            <div className="md:max-w-[80%] lg:max-w-[65%] xl:max-w-[60%] bg-white/40 mt-6 sm:mt-8 md:mt-10 p-10">
              <span className="block text-3xl text-black drop-shadow-lg">Connecting Europe and Africa through sustainable investments</span>
              <span className="mt-4 block text-lg text-black drop-shadow-lg">We are an ecosystem of stakeholders promoting and facilitating sustainable investments in Africa</span>
              <Link to="/about-us" className="inline-block mt-10 rounded-full bg-bahyellow px-6 py-3">LEARN MORE</Link>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
