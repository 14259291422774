import React from 'react';

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      validationErrors: {
        name: null,
        email: null,
        subject: null,
        message: null,
      },
      isLoading: false,
      responseStatus: null,
    };
  }

  handleInputChange = (field, value) => {
    const validationErrorMessage = this.getValidationErrorMessage(field, value);
    const updatedState = {
      formFields: {
        ...this.state.formFields,
        [field]: value,
      },
      validationErrors: {
        ...this.state.validationErrors,
        [field]: validationErrorMessage,
      }
    };
    this.setState(updatedState);
  }

  getValidationErrorMessage = (field, value) => {
    switch (field) {
      case "name":
        return !value ? "Name is required" : null;
      case "email":
        return !value ? "Email is required" : (!/^\S+@\S+$/.test(value) ? "Invalid email" : null);
      case "subject":
        return !value ? "Subject is required" : null;
      case "message":
        return !value ? "Message is required" : null;
      default:
        return null;
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState((state) => {
      let formHasValidationErrors = false;
      const validationErrors = Object.keys(state.formFields).reduce((previousValue, field) => {
        const validationErrorMessage = this.getValidationErrorMessage(field, state.formFields[field]);
        if (validationErrorMessage) {
          formHasValidationErrors = true;
        }
        return {
          ...previousValue,
          [field]: validationErrorMessage,
        };
      }, {});
      return {
        validationErrors,
        isLoading: !formHasValidationErrors,
      };
    }, () => {
      if (this.state.isLoading) {
        fetch("https://api.brusselsafricahub.eu/contact", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: this.state.formFields.name,
            email: this.state.formFields.email,
            subject: this.state.formFields.subject,
            message: this.state.formFields.message
          }),
        }).then((response) => response.text().then((data) => {
            const validationErrors = data ? JSON.parse(data).validationErrors : {};
            this.setState((state) => ({
              validationErrors: {
                ...state.validationErrors,
                ...validationErrors,
              },
              isLoading: false,
              responseStatus: response.status,
            }));
          })
        )
        .catch((error) => {
          this.setState({
            isLoading: false,
            responseStatus: 500
          });
        });
      }
    });
  }

  render() {
    return (
      <>
        {(!this.state.responseStatus || this.state.responseStatus === 400) &&
          <form onSubmit={this.handleSubmit} className="flex flex-col items-start">
            <label htmlFor="name" className="mt-1">Name <span className="text-red-500">*</span></label>
            <input
              id="name"
              type="text"
              value={this.state.formFields.name}
              onChange={(event) => this.handleInputChange('name', event.target.value)}
              className={`${this.state.validationErrors.name ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            />
            {this.state.validationErrors.name && <span className="text-red-500 text-sm">{this.state.validationErrors.name}</span>}
            <label htmlFor="email" className="mt-4">Email <span className="text-red-500">*</span></label>
            <input
              id="email"
              type="text"
              value={this.state.formFields.email}
              onChange={(event) => this.handleInputChange('email', event.target.value)}
              className={`${this.state.validationErrors.email ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            />
            {this.state.validationErrors.email && <span className="text-red-500 text-sm">{this.state.validationErrors.email}</span>}
            <label htmlFor="subject" className="mt-4">Subject <span className="text-red-500">*</span></label>
            <input
              id="subject"
              type="text"
              value={this.state.formFields.subject}
              onChange={(event) => this.handleInputChange('subject', event.target.value)}
              className={`${this.state.validationErrors.subject ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            />
            {this.state.validationErrors.subject && <span className="text-red-500 text-sm">{this.state.validationErrors.subject}</span>}
            <label htmlFor="message" className="mt-4">Message <span className="text-red-500">*</span></label>
            <textarea
              id="message"
              rows="7"
              value={this.state.formFields.message}
              onChange={(event) => this.handleInputChange('message', event.target.value)}
              className={`${this.state.validationErrors.message ? 'border-red-500' : undefined} w-5/6 lg:w-2/3 mt-1.5 border rounded-md p-1.5`}
            />
            {this.state.validationErrors.message && <span className="text-red-500 text-sm">{this.state.validationErrors.message}</span>}      
            {this.state.isLoading ?
              <button
                type="button"
                className="cursor-not-allowed mt-6 rounded-full bg-bahyellow px-6 py-3"
              >
                <svg className="inline-block animate-spin -ml-1 -mt-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="black" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="black" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Sending...
              </button> :
              <button
                type="button"
                className="cursor-pointer mt-6 rounded-full bg-bahyellow px-6 py-3"
                onClick={(event) => this.handleSubmit(event)}
              >
                Submit
              </button>
            }
          </form>
        }
        {this.state.responseStatus === 200 &&
          <span className="block text-green-600">Your message has been received. We will get back to you as soon as possible.</span>
        }
        {this.state.responseStatus && this.state.responseStatus !== 200 && this.state.responseStatus !== 400 &&
          <span className="block text-red-500">Something went wrong. Please email us at <a href="mailto:info@brusselsafricahub.eu">info@brusselsafricahub.eu</a>.</span>
        }
      </>
    );
  }
}