const teamMembers = [
  {
    name: 'T. Mouctar Bah',
    image: process.env.PUBLIC_URL + '/img/team-members/mouctar-bah.jpg',
    linkedin: 'https://www.linkedin.com/in/mouctar-bah-48a8a425/',
    position: 'Board member (president) | ExCo (capacity-building initiatives) | Founding member',
    bio: [
      'Business Development Manager @ Nagelmackers',
      'Member Of The Management Committee @ Belgian Finance Club',
      'PhD Student @ Ghent University',
      'Capital Market Expert: Collateral Management, Derivatives, Repo, Lending, Clearing, EMIR, MiFID/R @ Initio'
    ],
    teams: ["boardMembers", "exco", "foundingMembers"]
  },
  {
    name: 'Annie Mutamba',
    image: process.env.PUBLIC_URL + '/img/team-members/annie-mutamba.jpg',
    linkedin: 'https://www.linkedin.com/in/anniemutamba/',
    position: 'Board member (vice president) | ExCo (research & policymaking initiatives) | Founding member',
    bio: [
      'Government & public affairs advisor',
      'Managing Director @ Meridia Partners',
      'Consultant @ EU Commission, UNECA, UNCDF',
      'Visiting Professor @ College of Europe',
      'Co-founder @ AfricaCommsWeek'
    ],
    teams: ["boardMembers", "exco", "foundingMembers"]
  },
  {
    name: 'Jean-Michel Trésor',
    image: process.env.PUBLIC_URL + '/img/team-members/jean-michel-tresor.jpg',
    linkedin: 'https://www.linkedin.com/in/jean-michel-trésor-76a0463/',
    position: 'ExCo (secretary-general)',
    bio: [
      'Partner @ SIA Cabinets d\'avocats Brussels & Kinshasa',
      'VP of Operations @ Afriwise',
      'Consultant (Legal Advisor) @ Booking.com',
      'Lawyer @ Rulkin, Hanoteau & Partners'
    ],
    teams: ["exco"]
  },
  {
    name: 'Arthur De Vylder',
    image: process.env.PUBLIC_URL + '/img/team-members/arthur-de-vylder.jpg',
    linkedin: 'https://www.linkedin.com/in/arthur-de-vylder-1a96505/',
    position: 'ExCo (treasurer) | Founding member',
    bio: [
      'Risk Integration & Support @ KBC Bank & Verzekering'
    ],
    teams: ["exco", "foundingMembers"]
  },
  {
    name: 'Joachim Lefever',
    image: process.env.PUBLIC_URL + '/img/team-members/joachim-lefever.jpeg',
    linkedin: 'https://www.linkedin.com/in/joachimlefever/',
    position: 'ExCo (CTO) | Founding member',
    bio: [
      'Freelance Technical / Functional Analyst',
      'Technical & Functional Analyst @ Skipr',
      'Software Engineer, Solution Architect & Project Manager @ Pozyx',
      'Co-Founder & CTO @ Ruddy',
      'IT / Business Consultant @ CGI'
    ],
    teams: ["exco", "foundingMembers"]
  },
  {
    name: 'Jean Julien Ilunga',
    image: process.env.PUBLIC_URL + '/img/team-members/jean-julien-ilunga.jpg',
    linkedin: 'https://www.linkedin.com/in/jkilunga/',
    position: 'Board member | ExCo (investment & advisory initiatives) | Founding member',
    bio: [
      'Managing Partner @ Galuni Consulting Associates',
      'Regional Manager | Central Africa @ iPiD',
      'Board member | Partnerships & investor relations @ african markets'
    ],
    teams: ["boardMembers", "exco", "foundingMembers"]
  },
  {
    name: 'Chantal Kamatari',
    image: process.env.PUBLIC_URL + '/img/team-members/chantal-kamatari.jpg',
    linkedin: 'https://www.linkedin.com/in/chantal-kamatari-3699915/',
    position: 'Board member | ExCo (networking & stakeholder engagement initiatives) | Founding member',
    bio: [
      'CSR Manager @ BNP Paribas Fortis',
      'Performance Manager @ BNP Paribas Fortis'
    ],
    teams: ["boardMembers", "exco", "foundingMembers"]
  },
  {
    name: 'Gilles Bazambanza',
    image: process.env.PUBLIC_URL + '/img/team-members/gilles-bazambanza.jpg',
    linkedin: 'https://www.linkedin.com/in/gilles-bazambanza-7006a814/',
    position: 'Board member | Founding member',
    bio: [
      'Founder @ KUNOZA',
      'Business & Functional Analyst @ Banque Internationale à Luxembourg (BIL)',
      'Project Manager & Business Analyst @ ING',
      'Business Development @ NLM Partners'
    ],
    teams: ["boardMembers", "foundingMembers"]
  },
  // {
  //   name: 'Thewodros Berhanu',
  //   image: process.env.PUBLIC_URL + '/img/team-members/thewodros-berhanu.jpg',
  //   linkedin: 'https://www.linkedin.com/in/thewodrosberhanu/',
  //   position: 'Board member',
  //   bio: [
  //     'Founder @ Europe Africa IP',
  //     'President @ BelgoEthiopian Association',
  //     'Certified ESG Analyst (CESGA ®)',
  //     'Investment Professional (Wealth Management, Fund Selection, Fund of Funds Management and Sustainable/Impact Finance)'
  //   ],
  //   teams: ["boardMembers"]
  // },
  {
    name: 'Kameel Keshav',
    image: process.env.PUBLIC_URL + '/img/team-members/kameel-keshav.jpeg',
    linkedin: 'https://www.linkedin.com/in/kameelkeshav/',
    position: 'Advisory board member',
    bio: [
      'Chief Financial Officer @ South African Housing and Infrastructure Fund',
      'Nom-Executive Director; Chair of Remuneration Committee & Member of the Audit Committee @ MetroFibre Networx',
      'Property Specialist @ RALI PROPERTIES'
    ],
    teams: ["advisoryBoardMembers"]
  },
  {
    name: 'Wim Wuyts',
    image: process.env.PUBLIC_URL + '/img/team-members/wim-wuyts.jpeg',
    linkedin: 'https://www.linkedin.com/in/wim-wuyts-1a30455/',
    position: 'Board member',
    bio: [
      'CEO @ WTS Global',
      'Guest Lecturer in Management & Philosophy @ Fontainebleau',
      'Program Director @ Fontainebleau',
      'Founder & Chair @ International School for Tax Leadership',
      'Chief Transformation Officer @ Bekaert'
    ],
    teams: ["boardMembers"]
  },
  {
    name: 'Yves Brosens',
    image: process.env.PUBLIC_URL + '/img/team-members/yves-brosens.jpeg',
    linkedin: 'https://www.linkedin.com/in/yvesbrosens/',
    position: 'Board member',
    bio: [
      'Partner @ PKM Africa',
      'Partner @ ALTIUS',
      'Lawyer - Partner @ DLA Piper',
      'Lawyer @ De Bandt van Hecke & Lagae (Linklaters)'
    ],
    teams: ["boardMembers"]
  },
  {
    name: 'Soraya Hakuziyaremye',
    image: process.env.PUBLIC_URL + '/img/team-members/soraya-hakuziyaremye.jpg',
    linkedin: 'https://www.linkedin.com/in/soraya-m-hakuziyaremye-2017581/',
    position: 'Founding member',
    bio: [
      'Deputy Governor @ National Bank of Rwanda (BNR)',
      'Minister @ Ministry of Trade and Industry',
      'Vice President, Financial Institutions/Financial Markets Risk @ ING'
    ],
    teams: ["foundingMembers"]
  },
  {
    name: 'Bernhard Ardaen',
    image: process.env.PUBLIC_URL + '/img/team-members/bernhard-ardaen.jpg',
    linkedin: 'https://www.linkedin.com/in/bernhard-ardaen-ab4b8913/',
    position: 'Founding member',
    bio: [
      'Partner @ B-Crown Advisors',
      'Independent Advisor with respect to Structured Finance, ABS, MBS',
      'Interdependent Advisor for Non Profit and Social Economy'
    ],
    teams: ["foundingMembers"]
  },
  {
    name: 'Mohsen Abdel Fattah',
    image: process.env.PUBLIC_URL + '/img/team-members/mohsen-abdel-fattah.jpeg',
    linkedin: 'https://www.linkedin.com/in/mohsen-abdelfattah/',
    position: 'Advisory board member',
    bio: [
      'Managing Director at the African Sports & Creative Institute (ASCI)',
      'Business Development Director at CEDEP',
      'ESG Consulting'
    ],
    teams: ["advisoryBoardMembers"]
  },
  {
    name: 'William McKenzie',
    image: process.env.PUBLIC_URL + '/img/team-members/william-mckenzie.jpg',
    linkedin: 'https://www.linkedin.com/in/william-mckenzie-3953252/',
    position: 'Advisory board member',
    bio: [
      'Director, Global Operations Strategy @ DAI',
      'Founder & Managing Partner @ Woodward Infrastructure Group',
      'External Advisor - Capital Projects & Infrastructure @ McKinsey & Company'
    ],
    teams: ["advisoryBoardMembers"]
  },
  {
    name: 'Youssef Travaly',
    image: process.env.PUBLIC_URL + '/img/team-members/youssef-travaly.jpg',
    linkedin: 'https://www.linkedin.com/in/youssef-travaly-0741884/',
    position: 'Advisory board member',
    bio: [
      'Founder @ AllSightsAfrica',
      'Co-founder & CEO @ EDPU Africa',
      'Senior Fellow & Leader of the Africa-Europe Strategic Task Force on Digital @ Friend of Europe / Africa-Europe Foundation',
      'Vice President for Sciences and Innovation @ AIMS global',
      'President @ AIMS Senegal'
    ],
    teams: ["advisoryBoardMembers"]
  },
  {
    name: 'Kris Vansanten',
    image: process.env.PUBLIC_URL + '/img/team-members/kris-vansanten.jpeg',
    linkedin: 'https://www.linkedin.com/in/krisvansanten/',
    position: 'Board member',
    bio: [
      'Chairman of the Board @ SOLTECH NV',
      'Board member @ Customer Collective',
      'Founder and Managing Partner @ Quanteus Group',
      'Founder and Managing Partner @ QuantIM',
      'Co-Founder and Managing Partner @ BrightWolves',
      'Co-Founder @ HIGHMIND',
      'Co-Founder @ UPTHRUST',
      'Founder and Managing Partner @ RSQ Investors'
    ],
    teams: ["boardMembers"]
  },
  // {
  //   name: 'Peter De Proft',
  //   image: process.env.PUBLIC_URL + '/img/team-members/peter-de-proft.jpeg',
  //   linkedin: 'https://www.linkedin.com/in/peter-de-proft-a697395/',
  //   position: 'Advisory board member',
  //   bio: [
  //     'Honorary Director General @ European Fund & Asset Management Association (EFAMA)',
  //     'CEO @ Lumax Consulting',
  //     'Strategic Advisor @ InvesTAO',
  //     'Advisor @ Citysafes Belgium',
  //     'Advisor @ Multichain Asset Managers Association',
  //     'Vice President @ Belgian Finance Center'
  //   ],
  //   teams: ["advisoryBoardMembers"]
  // },
  {
    name: 'Guillaume Grange',
    image: process.env.PUBLIC_URL + '/img/team-members/guillaume-grange.jpeg',
    linkedin: 'https://www.linkedin.com/in/guillaume-grange-8153a891/',
    position: 'Advisory board member',
    bio: [
      'Independent Advisor',
      'Conducting Officer - Head of Legal and Compliance @ Generali Investments Luxembourg',
      'Deputy Head of Fund Structuring @ CACEIS Bank Luxembourg'
    ],
    teams: ["advisoryBoardMembers"]
  },
  {
    name: 'Laurent Marliere',
    image: process.env.PUBLIC_URL + '/img/team-members/laurent-marliere.jpeg',
    linkedin: 'https://www.linkedin.com/in/laurentmarliere/',
    position: 'Advisory board member',
    bio: [
      'CEO @ DealFox Marketplace',
      'President & CEO @ ISFIN (Emerging Markets Advisors)',
      'Member Of The Management Board @ Belgian Finance Club',
      'Committee Member @ World Alliance of International Financial Centers (WAIFC)',
      'Managing Partner - Senior Consultant @ SCIPION - Strategy & Marketing'
    ],
    teams: ["advisoryBoardMembers"]
  },
  {
    name: 'Evelyne Van Wassenhove',
    image: process.env.PUBLIC_URL + '/img/team-members/evelyne-van-wassenhove.jpeg',
    linkedin: 'https://www.linkedin.com/in/evelynevanwassenhove/',
    position: 'Advisory board member',
    bio: [
      'Group Partner and CFO @ Quanteus Group',
      'Co-Founder and Managing Partner @ RSQ Investors',
      'Co-Founder and Managing Partner @ BrightWolves',
      'Board Member @ Customer Collective',
      'Co-Founder and Managing Partner @ Digit Mint',
      'Co-Founder and Managing Partner @ QuantIM'
    ],
    teams: ["advisoryBoardMembers"]
  },
  {
    name: 'Dr Lwanga Elizabeth Nanziri',
    image: process.env.PUBLIC_URL + '/img/team-members/elizabeth-nanziri.jpeg',
    linkedin: 'https://www.linkedin.com/in/elizabeth-nanziri',
    position: 'Advisory board member',
    bio: [
      'Senior Lecturer/Head PGDip Development Finance @ Stellenbosch Business School',
      'Researcher @ AFrican Economic Research Consortium (AERC)',
      'Graduate Research Associate @ University of Cape Town',
      'CEO @ South African Savings Institute'
    ],
    teams: ["advisoryBoardMembers"]
  },
  {
    name: 'Velvet Basemera-Fitzpatrick, PhD',
    image: process.env.PUBLIC_URL + '/img/team-members/velvet-fitzpatrick.jpeg',
    linkedin: 'https://www.linkedin.com/in/vbfitzpatrick/',
    position: 'Advisory board member',
    bio: [
      'Global Executive MBA @ INSEAD \'24',
      'Advisor @ Loyal VC',
      'Senior Program Officer, Cooperative Research Programs @ Transportation Research Board: National Academies of Sciences, Engineering, and Medicine'
    ],
    teams: ["advisoryBoardMembers"]
  },
  {
    name: 'Loïc De Cannière',
    image: process.env.PUBLIC_URL + '/img/team-members/loic-de-canniere.jpg',
    linkedin: 'https://www.linkedin.com/in/lo%C3%AFc-de-canni%C3%A8re-a828378/',
    position: 'Advisory board member',
    bio: [
      'Chair of the Supervisory Board @ Incofin Investment Management',
      'Chair of the Board of Directors @ Social Performance Task Force',
      'Director @ Finance Trust Bank Uganda',
      'Chair of the Board of Directors @ Karel de Grote Hogeschool Antwerp'
    ],
    teams: ["advisoryBoardMembers"]
  },
  {
    name: 'Didier Tshidimba',
    image: process.env.PUBLIC_URL + '/img/team-members/didier-tshidimba.jpg',
    linkedin: 'https://www.linkedin.com/in/didier-tshidimba-88313b/',
    position: 'Board member',
    bio: [
      'Managing Partner Nordics @ Roland Berger'
    ],
    teams: ["boardMembers"]
  },
];

export default teamMembers;