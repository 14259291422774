export default function H2(props) {
  return (
    <>
      <h2 className={`text-2xl text-slate-800 font-medium mt-8 ${props.titleClassName ? props.titleClassName : ""}`}>
        {props.title}
      </h2>
      {props.subtitle &&
        <span className={`block text-xs ${props.subtitleClassName ? props.subtitleClassName : ""}`}><i>{props.subtitle}</i></span>
      }
      {props.children}
      <hr className={`border-bahyellow border-b border-t-0 mt-1.5 mb-3 ${props.hrClassName ? props.hrClassName : ""}`} />
    </>
  )
}