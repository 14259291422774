import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import H2 from "../components/H2"
import ContactForm from "../components/forms/ContactForm"

export default function Contact() {
  return (
    <div className="flex flex-wrap mb-20 lg:mb-32">
      <div className="w-full md:w-2/3">
        <H2 title="Contact us" />
        <ContactForm />
      </div>
      <div className="w-full hidden md:flex flex-col md:w-1/3 md:pl-12 md:mt-36">
        <p>
          <span className="font-semibold">Brussels-Africa Hub AISBL</span><br/>
          <i>International Non-Profit Organisation</i><br/><br/>
          Place Albert Leemans 14 box 11<br/>
          B 1050 Ixelles, Belgium<br/>
          BE 0682.586.426<br/>
          <a href="mailto:info@brusselsafricahub.eu">info@brusselsafricahub.eu</a>
        </p>
        <div className="flex flex-row mt-4">
          <a href="https://www.linkedin.com/company/brussels-africa-hub/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className="text-lg"/>
          </a>
        </div>
      </div>
    </div>
  );
}